// store/index.js
//Redux Store
import {configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'


const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production', //to disbale redux devtools in  prod
    reducer: {
  dummyReducer: (state = {}, action) => {
    switch (action.type) {
      // Add cases for different actions here
      default:
        return state;
    }
  }
}


});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

//Exporting actions
export {store}

//Exporting thunks
//export { useSearchSuggestionsQuery } from './apis/searchApi'













