// CleanLandingPage.js
import React from 'react';
import { Helmet } from "react-helmet";
import { websiteName } from '../utils/environment';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>{websiteName}</title>
      </Helmet>
      <div className="navbar bg-base-300 fixed top-0 w-full z-50">
        <div className="flex-1">
          <a className="btn btn-ghost normal-case text-xl" onClick={() => document.getElementById('home').scrollIntoView({ behavior: 'smooth' })}>CraaftyPop</a>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal p-0">
          {/* <li><a onClick={() => document.getElementById('home').scrollIntoView({ behavior: 'smooth' })}>Home</a></li> */}
            <li><a onClick={() => document.getElementById('1').scrollIntoView({ behavior: 'smooth' })}>Our Story</a></li>
            <li><a onClick={() => document.getElementById('2').scrollIntoView({ behavior: 'smooth' })}>Sign Up</a></li>
          </ul>
        </div>
      </div>
<div id="home" className="hero bg-base-200" style={{ 
  backgroundImage: " url('/assests/10.png')",
  backgroundSize: 'cover', 
  backgroundPosition: 'center',
  height: '400px' // Custom height
}}>
  <div className="hero-content flex-col lg:flex-row-reverse text-white">
    <div>
      {/* <h1 className="text-5xl font-bold">Discover, Connect, Grow</h1>
      <h2 className="text-xl font-semibold py-6">Discover Local Crafts Fairs Tailored for Small Businesses.</h2>
      <p className="max-w-screen-md mx-auto text-lg mb-10">At CraaftyPop, we specialize in connecting small businesses and craftspeople with local crafts fairs. Dive into a community that values unique, handcrafted products just like yours. Unlike larger, generic platforms, we focus on promoting local events that help you grow your business in a supportive environment. Sign up today and experience a marketplace where your creativity earns the spotlight it deserves!</p> */}
     
    </div>
  </div>
</div>
     <div id="1" className="hero min-h-screen bg-base-200">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <img src="path/to/your/image.jpg" className="max-w-sm rounded-lg shadow-2xl" alt="A relevant description" />
          <div>
            <h1 className="text-5xl font-bold">Our Story</h1>
            <h2 className="text-xl font-semibold py-6">Discover Local Crafts Fairs Tailored for Small Businesses.</h2>
            <p className="max-w-screen-md mx-auto text-lg mb-10">At CraaftyPop, we specialize in connecting small businesses and craftspeople with local crafts fairs. Dive into a community that values unique, handcrafted products just like yours. Unlike larger, generic platforms, we focus on promoting local events that help you grow your business in a supportive environment. Sign up today and experience a marketplace where your creativity earns the spotlight it deserves!</p>
            <div className="flex items-center space-x-2">
              <input type="text" placeholder="Enter your email" className="input input-bordered" />
              <button className="btn btn-primary">Sign Up</button>
            </div>
          </div>
        </div>
      </div>
      <div id="2" className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img src="path/to/your/image.jpg" className="max-w-sm rounded-lg shadow-2xl" alt="A relevant description" />
        <div>
          <h1 className="text-5xl font-bold">Discover, Connect, Grow</h1>
          <h2 className="text-xl font-semibold py-6">Discover Local Crafts Fairs Tailored for Small Businesses.</h2>
          <p className="max-w-screen-md mx-auto text-lg mb-10">At CraaftyPop, we specialize in connecting small businesses and craftspeople with local crafts fairs. Dive into a community that values unique, handcrafted products just like yours. Unlike larger, generic platforms, we focus on promoting local events that help you grow your business in a supportive environment. Sign up today and experience a marketplace where your creativity earns the spotlight it deserves!</p>
          <div className="flex items-center space-x-2">
            <input type="text" placeholder="Enter your email" className="input input-bordered" />
            <button className="btn btn-primary">Sign Up</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default LandingPage;