// App.js
import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect } from 'react';

import LandingPage from './components/LandingPage';
import SimpleLoader from './components/Loaders/SimpleLoader';

const App = () => {




  return (
    <div>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<LandingPage />} />
         
        </Routes>

      </BrowserRouter>

    </div>
  );
};

export default App;
